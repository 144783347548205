import {
    createStyles,
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
} from '@mantine/core';
import { IconUserCheck, IconChartDots2, IconUsers } from '@tabler/icons';
  
  const mockdata = [
    {
      title: 'Thriving Team',
      description:
        'A Happy team promotes collaboration and creativity, boosting productivity. Empowered employees create a positive environment that drives innovation and overall success',
      icon: IconUsers,
    },
    {
      title: 'Satisfied Customer',
      description:
        'A happy customer enjoys exceptional service and support, leading to loyalty and positive referrals, which drive business growth and success.',
      icon: IconUserCheck,
    },
    {
      title: 'Rapid Growth',
      description:
        'Accelerated growth results from effective strategies and strong teamwork, leading to increased revenue, market expansion, and enhanced customer satisfaction.',
      icon: IconChartDots2,
    },
  ];
  
  const useStyles = createStyles((theme) => ({
    title: {
      fontSize: 30,
      fontWeight: 900,
      [theme.fn.smallerThan('sm')]: {
        fontSize: 24,
      },
    },
    cardIcon: {
      position: 'relative',
      zIndex: 1,
      stroke: '#684BCB',
    }, 

    description: {   
      maxWidth: 625,
      margin: 'auto',
    },
  
    card: {
      boxShadow: '0 5px 30px rgb(4 75 194 / 10%)',
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
    },
  
    cardTitle: {
      '&::before': {
        content: '""',
        display: 'block',
        backgroundColor: '#14D3FF',
        width: 45,
        height: 45,
        borderRadius: 50,
        opacity: .7,
        position: 'absolute',
        left: 70,
        top: 60,
      },
    },
  }));
  
  export function Features(props:any) {
    console.log(props.targetRef)
    const { classes, theme } = useStyles();
    const features = mockdata.map((feature) => (
      <Card key={feature.title} radius="lg" className={classes.card} p={theme.spacing.xl*2}>
        <feature.icon className={classes.cardIcon} size={50} stroke={2} color={theme.fn.primaryColor()} />
        <Text size="lg" weight={700} className={classes.cardTitle} mt="xl">
          {feature.title}
        </Text>
        <Text size={15} color="dimmed" weight={500} mt="sm">
          {feature.description}
        </Text>
      </Card>
    ));
    return (
      <Container id='#features' mt={70} size="lg" py={100}  ref={props.targetRef}>
        <Group position="center">
          <Badge variant="light" color='teal' size="lg">
          What you’ll achieve!
          </Badge>
        </Group>
        <Title className={classes.title} align="center" mt="sm">Happy Team + Happy Customer = Accelerated Growth</Title>
  
        <Text color="dimmed" className={classes.description} align="center" mt="md">
          A motivated team leads to satisfied customers, driving success and growth. When employees thrive, they deliver exceptional service, fostering loyalty and expanding your business
        </Text>
  
        <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} style={{position: 'relative'}}>
          <img style={{position: 'absolute', right: '-100px', bottom: '170px', maxWidth: '200px'}} src="./images/asset17.svg" alt="Shape" />
          {features}
        </SimpleGrid>
      </Container>
    );
  }
export default Features;