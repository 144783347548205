import { useState } from "react";
import {
  Badge,
  Card,
  Container,
  Grid,
  List,
  Group,
  useMantineTheme,
  Text,
  Title,
  Center,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons";
import CTA from "./CTA";

function PriceSx({ oneHundred = "" }) {
  const theme = useMantineTheme();

  //   const priceValue = () => {
  //     if(value === '500'){'$50'} else{'$20'}
  //  }

  return (
    <section id="pricing" className="pricing-list">
      <Center className="center">
        <div>
          <Title order={1} fz={60} fw={900}>Revealing Soon</Title>
          <Text pt={15}>Something exciting is coming soon! Stay tuned for the big reveal—we can't wait to share it with you!</Text>
        </div>
      </Center>
      <Container size="lg" py={90} style={{filter: 'blur(9px)'}}>
        <Grid gutter={0} align="center" pb={100}>
          <Grid.Col md={12} style={{ textAlign: "center" }}>
            <Group position="center">
              <Badge variant="light" color="lime" size="lg">
                Pricing Plans
              </Badge>
            </Group>
            <Title order={1} weight={900} align="center" mt="sm" mb={20}>
              Try CommFlow free or Pro
            </Title>

            <Text max-w={600} mb={50} color="#677788" style={{margin: '0 auto 80px', maxWidth: '800px'}}>Choose the plan that suits your business. From startups to growing teams, our flexible pricing helps streamline operations and enhance customer service. Upgrade anytime as you grow!</Text>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Card
              className="item extend"
              shadow="sm"
              p="lg"
              radius="xl"
              withBorder
              sx={(theme) => ({
                marginRight: "-1px",
                maxWidth: '450px',
                borderRadius: '2rem 0 0 2rem ',
                '@media (max-width: 767px)': {
                  borderRadius: '2rem',
                    margin: '0 auto 40px'
                },
              })}
            >

              <Group position="apart" mt="md" mb="xs">
                <Title color="indigo" order={4} weight={600}>
                  Free
                </Title>
              </Group>
              <Title order={1} size="h1" color="black">
                $49
                <sub className="">
                  <small>/month</small>
                </sub>
              </Title>
              <Text size="sm" color="dimmed">
                Lorem ipsum you can explore more of the magical fjord landscapes
                with tours.
              </Text>
              <List mb="md">
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Live Chat</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Messenger integration</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Email integration</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Ticketing system</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Multilanguage</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Widget customization</List.Item>
              </List>
            </Card>
          </Grid.Col>
          
          <Grid.Col sm={4}>
            <Card
              className="item"
              shadow="sm"
              p="lg"
              radius="xl"
              withBorder
              sx={(theme) => ({
                '@media (max-width: 767px)': {
                    margin: '0 auto 40px',
                    shadow: 'none !important'
                },
              })}
            >

              <Group position="apart" mt="md" mb="xs">
                <Title color="indigo" order={4} weight={600}>
                  Professional
                </Title>
                <Badge color="pink" variant="light">
                  Exclusively Pro
                </Badge>
              </Group>
              <Title order={1} size="h1" color="black">
                $399
                <sub className="">
                  <small>/month</small>
                </sub>
              </Title>
              <Text size="sm" color="dimmed">
                Lorem ipsum you can explore more of the magical fjord landscapes
                with tours.
              </Text>
              <List mb="md">
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Live Chat</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Messenger integration</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Email integration</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Ticketing system</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Multilanguage</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Widget customization</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Live visitor list</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Chatbot triggers</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Tickets</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Livechat conversations</List.Item>
              </List>
            </Card>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Card
              className="item extend"
              shadow="sm"
              p="lg"
              radius="xl"
              withBorder

              sx={(theme) => ({
                marginLeft: "-1px", 
                borderRadius: '0 2rem 2rem 0',
                '@media (max-width: 767px)': {
                  borderRadius: '2rem',
                    margin: '0 auto 40px'
                },
              })}
            >

              <Group position="apart" mt="md" mb="xs">
                <Title color="indigo" order={4} weight={600}>
                  Business
                </Title>
              </Group>
              <Title order={1} size="h1" color="black">
               $99
                <sub className="">
                  <small>/month</small>
                </sub>
              </Title>
              <Text size="sm" color="dimmed">
                Lorem ipsum you can explore more of the magical fjord landscapes
                with tours.
              </Text>
              <List mb="md">
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Live Chat</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Messenger integration</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Email integration</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Ticketing system</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Multilanguage</List.Item>
                <List.Item><IconCheck stroke={2.5} size={20} color="indigo" />Widget customization</List.Item>
              </List>
            </Card>
          </Grid.Col>
        </Grid>
        <CTA />
      </Container>
    </section>
  );
}
export default PriceSx;
