import { createStyles, Text, Avatar, Group, Title } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  body: {
    paddingLeft: 54,
    paddingTop: theme.spacing.sm,
  },
}));



export default function Comments(props:any) {
  const { classes } = useStyles();
  return (
    <div>
        <Title py='xl'
        order={2}
        size="h2"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}` })}
        weight={900}
        align="center"
      >
        (3) Comments:
      </Title>
        {props.comData.map((cmmt:any)=>{
            const {author,postedAt,body}=cmmt;
    return (
            <ul style={{paddingLeft: 0, listStyle: 'none'}}>
                <li>
                    <Group>
                        <Avatar src={author.image} alt={author.name} radius="xl" />
                        <div>
                        <Text size="sm">{author.name}</Text>
                        <Text size="xs" color="dimmed">
                            {postedAt}
                        </Text>
                        </div>
                    </Group>
                    <Text className={classes.body} size="sm">
                        {body}
                    </Text>
                </li>
            </ul>
            )
        })}
    </div>
  );
}