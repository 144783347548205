import { Button, Container, Grid, Image, Paper, Title, TypographyStylesProvider } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons';

function HeaderHero(props:any) {
  return (
    <Paper component="section" className="home"
      sx={(theme) => ({
        padding: '200px 0 200px',
        '@media (max-width: 991px)': {
          padding: '70px 0 0',
        },
      })}
    >
      <div className="hero-img">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" color='#EBF1FE' viewBox="191.167 0 420.833 411.166">
          <g>
            <path fill="currentColor" d="M243.164,156.312c-21.646,21.532-35.852,49.413-40.552,79.58c-0.17,1.108-0.333,2.208-0.475,3.311c-0.861,6.393-1.292,12.836-1.292,19.287c0.002,8.043,0.669,16.071,1.994,24.004c0.135,0.802,0.277,1.611,0.425,2.412c2.131,11.542,5.667,22.779,10.529,33.462c0.291,0.646,0.59,1.3,0.896,1.946c4.576,9.654,10.212,18.77,16.805,27.178c0.406,0.535,0.834,1.062,1.26,1.587c6.529,8.093,13.902,15.466,21.995,21.995c0.527,0.428,1.055,0.855,1.584,1.257c8.409,6.594,17.527,12.232,27.184,16.81c0.646,0.305,1.3,0.604,1.943,0.894c10.816,4.922,22.201,8.488,33.893,10.616c0.809,0.143,1.611,0.276,2.417,0.404c14.173,2.342,28.617,2.557,42.854,0.639c1.108-0.143,2.208-0.305,3.316-0.476c30.167-4.7,58.046-18.907,79.578-40.552l165.649-165.649V-1.833H401.309L243.164,156.312z"></path>
          </g>
        </svg>
       </div>
         <Container size="lg">
            <Grid align='center'>
                <Grid.Col md={7} sm={12}>
                  <div className='content' style={{position: 'relative'}}>
                    <img style={{position: 'absolute', left: '-80px', top: '-55px', maxWidth: '150px'}} src="./images/asset17.svg" alt="Shape" />
                    <TypographyStylesProvider style={{maxWidth: '570px', position: 'relative'}}>
                        <Title order={1} fz={100} fw={900}>Streamlined Platform for Team Management and Customer Services</Title>
                        <svg
                          style={{position:'absolute', width: '85%', height: '55px', left: 0, top: 50, fill: '#B3D6FD', zIndex: -1}}
                          preserveAspectRatio="none"
                          viewBox="0 0 418 42"
                        >
                          <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749c-31.879 5.313-49.1 9.017-61.296 13.185-4.76 1.627-5.176 1.844-5.13 2.676.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066z"></path>
                        </svg>
                        <p>Empower your team with a unified and intuitive platform for seamless collaboration, task management, and customer engagement. Simplify communication, streamline workflows, and provide exceptional service—all from one place. Whether you’re coordinating with your team or assisting customers, our platform offers everything you need to stay connected, organized, and productive.</p>
                        <a href='https://app.commflow.io/'><Button variant="filled" color='indigo' size="lg" mr="sm">Get Started</Button></a>
                        <Button variant="light" color='green' size="lg" rightIcon={<IconArrowRight size={18} />} onClick={() => props.scrollIntoView()}>Learn More</Button>
                    </TypographyStylesProvider>
                  </div>
                </Grid.Col>
                <Grid.Col md={5}
                  sx={(theme) => ({
                    '@media (max-width: 991px)': {
                      display: 'none',
                    },
                  })}
                >
                <Image className='app-screen desktop'
                  radius="lg"
                  src="./images/app.jpg"
                  alt="Chat App Desktop"
                />
                {/* <Image className='app-screen mobile'
                  src="./images/asset16.png"
                  alt="Chat App Mobile"
                /> */}
                <Image className='app-screen mobile circle'
                  src="./images/asset19.png"
                  alt="Chat App Mobile"
                />
                </Grid.Col>
            </Grid>

         </Container>
    </Paper>
  );
}

export default HeaderHero;
