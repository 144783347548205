import { useEffect, useState } from "react";
import useScrollListener from '../hooks/useScrollListener';
import {
    createStyles,
    Header,
    Group,
    Button,
    UnstyledButton,
    Text,
    ThemeIcon,
    Divider,
    Box,
    Burger,
    Drawer,
    ScrollArea,
    Container,
    Image,
  } from '@mantine/core';
  import { useDisclosure } from '@mantine/hooks';
  import {
    IconNotification,
    IconCode,
    IconBook,
    IconChartPie3,
    IconFingerprint,
    IconCoin,
  } from '@tabler/icons';
import { Link } from 'react-router-dom';
  
  const useStyles = createStyles((theme) => ({
    link: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontWeight: 700,
      textTransform: 'upperCase',
      fontSize: theme.fontSizes.sm,
  
      [theme.fn.smallerThan('sm')]: {
        height: 42,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
  
      ...theme.fn.hover({
        color: 'blue',
        backgroundColor: 'transparent',
      }),
    },
    linkActive: {
      '&, &:hover': {
        backgroundColor: 'transparent',
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
  
    // subLink: {
    //   width: '100%',
    //   padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    //   borderRadius: theme.radius.md,
  
    //   ...theme.fn.hover({
    //     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    //   }),
  
    //   '&:active': theme.activeStyles,
    // },
  
    // dropdownFooter: {
    //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    //   margin: -theme.spacing.md,
    //   marginTop: theme.spacing.sm,
    //   padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
    //   paddingBottom: theme.spacing.xl,
    //   borderTop: `1px solid ${
    //     theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    //   }`,
    // },
  
    hiddenMobile: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },
  
    hiddenDesktop: {
      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },
  }));
  
  const mockdata = [
    {
      icon: IconCode,
      title: 'Open source',
      description: 'This Pokémon’s cry is very loud and distracting',
    },
    {
      icon: IconCoin,
      title: 'Free for everyone',
      description: 'The fluid of Smeargle’s tail secretions changes',
    },
    {
      icon: IconBook,
      title: 'Documentation',
      description: 'Yanma is capable of seeing 360 degrees without',
    },
    {
      icon: IconFingerprint,
      title: 'Security',
      description: 'The shell’s rounded shape and the grooves on its.',
    },
    {
      icon: IconChartPie3,
      title: 'Analytics',
      description: 'This Pokémon uses its flying ability to quickly chase',
    },
    {
      icon: IconNotification,
      title: 'Notifications',
      description: 'Combusken battles with the intensely hot flames it spews',
    },
  ];
  
  // window.scroll(function() {
	// 	let scroll = window.scrollTop();

	// 	if (scroll >= 50) {
	// 		document.getElementByClassName("sticky").classList.add("nav-sticky");
	// 	} else {
	// 		document.getElementByClassName("sticky").classList.remove("nav-sticky");
	// 	}
		
	// 	if (this.scrollTop() > 80) {
	// 		document.getElementByClassName("back-to-top").fadeIn();
	// 	} else {
	// 		document.getElementByClassName("back-to-top").fadeOut();
	// 	}
	// });
	// let scroll = window.scrollTop();

	// if (scroll > 50) {
	// 	document.getElementByClassName("sticky").classList.add("nav-sticky");
	// }
  
  export function MenuHeader() {

    const menuName = [ 'Home', 'Pricing', 'Docs', 'Features', 'Blog', 'Contact', 'Get Started'];
    const [ home, pricing, docs, features, blog, contact, btn] = menuName;
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const { classes, theme } = useStyles();

    /* START: Add class on Scroll */
    const [menus, setActive ] = useState(false);
    const changedBg = () => {
      if (window.scrollY >= 100) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
    window.addEventListener('scroll', changedBg);
    /* END: Add class on Scroll */

    /* START: Header hide/show on Scroll up/down */
  const [menuClasses, setMenuClasses] = useState([]);
  const scroll = useScrollListener();

  // update classList of nav on scroll
  useEffect(() => {
    const classList = [];

    if (scroll.y > 100 && scroll.y - scroll.lastY > 0)
      classList.push("add-to-scroll-down");

    setMenuClasses(classList);
  }, [scroll.y, scroll.lastY]);
   /* END: Header hide/show on Scroll up/down */
  
    const links = mockdata.map((item) => (
      <UnstyledButton key={item.title}>
        <Group noWrap align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <item.icon size={22} color={theme.fn.primaryColor()} />
          </ThemeIcon>
          <div>
            <Text size="sm" weight={500}>
              {item.title}
            </Text>
            <Text size="xs" color="dimmed">
              {item.description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    ));
  
    return (
      
      <Box className={`${menus ? 'menus active': 'menus'} ${menuClasses.join(" ")}`}>
        <Container size="lg">
          <Header height={60}>
            <Group position="apart" sx={{ height: '100%' }}>
              <Link to={'/'} style={{ width: 180}}>
                <Image
                  src="/images/commflow-logo.png"
                  alt="CommFlow Logo"
                />
              </Link>
              <Group sx={{ height: '100%' }} spacing={0} className={classes.hiddenMobile}>
                <Link to={'/'} className={classes.link}>Home</Link>
                {/* <Link to={'/featuredpage'} className={classes.link}>features</Link> */}
                <Link to={'/pricingPage'} className={classes.link}>pricing</Link>
                {/* <Link to={'/docsPage'} className={classes.link}>docs</Link> */}
                {/* <Link to={'/bloglayout'} className={classes.link}>blog</Link> */}
                <Link to={'/contact'} className={classes.link}>contact</Link>
                {/* <HoverCard width={600} position="bottom" radius="md" shadow="md" withinPortal>
                  <HoverCard.Target>
                    <a href="#" className={classes.link}>
                      <Center inline>
                        <Box component="span" mr={5}>
                          Features
                        </Box>
                        <IconChevronDown size={16} color={theme.fn.primaryColor()} />
                      </Center>
                    </a>
                  </HoverCard.Target>
    
                  <HoverCard.Dropdown sx={{ overflow: 'hidden' }}>
                    <Group position="apart" px="md">
                      <Text weight={500}>Features</Text>
                      <Anchor href="#" size="xs">
                        View all
                      </Anchor>
                    </Group>
    
                    <Divider
                      my="sm"
                      mx="-md"
                      color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
                    />
    
                    <SimpleGrid cols={2} spacing={0}>
                      {links}
                    </SimpleGrid>
    
                    <div className={classes.dropdownFooter}>
                      <Group position="apart">
                        <div>
                          <Text weight={500} size="sm">
                            Get started
                          </Text>
                          <Text size="xs" color="dimmed">
                            Their food sources have decreased, and their numbers
                          </Text>
                        </div>
                        <Button variant="default">Get started</Button>
                      </Group>
                    </div>
                  </HoverCard.Dropdown>
                </HoverCard> */}
              </Group>
    
              <Group className={classes.hiddenMobile}>
                <a href='https://app.commflow.io/login' target="blank"><Button variant="filled" color="indigo" size="md">Get Started</Button></a>
              </Group>
    
              <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
            </Group>
          </Header>
        </Container>
  
        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="xl"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <Link to={'/'}>
            <Image sx={{marginTop: '-40px', paddingBottom: '10px'}}
              width={150}
              src="/images/commflow-logo.png"
              alt="CommFlow Logo"
            />
          </Link>
          <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx="-md">
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
  
            <Link to={'/'} className={classes.link}>home</Link>
            <Link to={'/featuredPage'} className={classes.link}>features</Link>
            <Link to={'/bloglayout'} className={classes.link}>blog</Link>
            <Link to={'/contact'} className={classes.link}>contact</Link>
            {/* <UnstyledButton className={classes.link} onClick={toggleLinks}>
              <Center inline>
                <Box component="span" mr={5}>
                  Features
                </Box>
                <IconChevronDown size={16} color='black' />
              </Center>
            </UnstyledButton>
            <Collapse in={linksOpened}>{links}</Collapse> */}
  
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
  
            <Group position="center" grow pb="xl" px="md">
            <Link to={'./commflowApp'}><Button variant="light" color="indigo" size="lg">Get Started</Button></Link>
            </Group>
          </ScrollArea>
        </Drawer>
      </Box>
    );
  }
  export default MenuHeader;