import React from 'react';
import ReactDOM from 'react-dom/client';
import {  BrowserRouter, BrowserRouter as Router,  Route, Routes } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import './App.css';

import Headerx from './components/headerx';
import HomePage from './components/homePage';
import FeaturedPage from './components/featuredPage';
import PricingPage from './components/pricingPage';
import DocsPage from './components/docsPage';
import BlogLayout from './components/blogLayout';
import BlogDetail from './components/blogDetail';
import Contact from './components/contactPage';
import Footerx from './components/footerx';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MantineProvider theme={{ fontFamily: "'Sen', sans-serif" }} withGlobalStyles withNormalizeCSS>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <Headerx />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/featuredPage' element={<FeaturedPage />} />
          <Route path='/pricingPage' element={<PricingPage />} />
          <Route path='/docsPage' element={<DocsPage />} />
          <Route path='/bloglayout' element={<BlogLayout />} />
          <Route path="/post/:id" element={<BlogDetail />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footerx />
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </MantineProvider> 
);
