import HeaderHero from './headerHero';
import Clients from './clients';
import Services from './services';
import Features from './features';
import Reviews from './reviews';
import CTA from './CTA';
import { useScrollIntoView } from '@mantine/hooks';

function HomePage() {
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 0 }); 
  
  return (
    <>  
      <div className='pattern'></div>
      <HeaderHero scrollIntoView={scrollIntoView}/>
      <Features targetRef={targetRef} />
      <Services />
      <Reviews/>
      <Clients />
      <CTA />
    </>
  );
}

export default HomePage;
