import { useWindowScroll } from '@mantine/hooks';
import { createStyles, Container, Group, ActionIcon, Button } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconChevronUp } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    position: 'relative',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export function MainFooter() {
  const { classes } = useStyles();
  const [scroll, scrollTo] = useWindowScroll();

  return (
    <div className={`${classes.footer} footer`}>
      <Container size="lg" className={classes.inner}>
        <div>
          <small style={{color: '#777'}}>CommFlow - 2024. All rights reserved.</small>
        </div>
        <Group spacing={0} className={classes.links} position="right" noWrap>
          <ActionIcon size="lg">
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandYoutube size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
        <Button  className='scroll-top' onClick={() => scrollTo({ y: 0 })} variant='outline' radius={4} p={0}  color='pink' style={{width: '35px', transform: 'rotate(45deg)', height: '35px', position: 'absolute', bottom: '22px', right: 'calc(50% - 25px)'}}
           sx={(theme) => ({
            '@media (max-width: 767px)': {
              display: 'none',
            }})}
        ><IconChevronUp size={30} /></Button>
      </Container>
    </div>
  );
}
export default MainFooter;