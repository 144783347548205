import React, { useState, useEffect } from "react";
import {
  ActionIcon,
  Avatar,
  Button,
  Center,
  createStyles,
  Container,
  Group,
  Image,
  Text,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconPlus,
  IconShare,
} from "@tabler/icons";
import axios from "axios";
import { useParams } from "react-router-dom";
import CommentForm from "./commentForm";
import Comments from "./comments";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  rating: {
    position: "absolute",
    top: theme.spacing.xs,
    right: theme.spacing.xs + 2,
    pointerEvents: "none",
  },

  title: {
    display: "block",
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.xs / 2,
  },

  action: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    }),
    borderRadius: "50px",
  },

  footer: {
    marginTop: theme.spacing.md,
  },
}));

const BlogDetail = (props) => {
  const [post, setPost] = useState({});
  const { id } = useParams();

  useEffect(() => {
    let wp_API_Url = `http://localhost:10039`;
    axios
      .get(`${wp_API_Url}/wp-json/wp/v2/posts/${id}`)
      .then((response) => {
        console.log(response);
        setPost(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const { classes, theme } = useStyles();
  const comData = [
    {
      postedAt: "10 minutes ago",
      body: "This Pokémon likes to lick its palms that are sweetened by being soaked in honey. Teddiursa concocts its own honey by blending fruits and pollen collected by Beedrill. Blastoise has water spouts that protrude from its shell. The water spouts are very accurate.",
      author: {
        name: "David Smith",
        image: "./images/asset24.jpeg",
      },
    },
    {
      postedAt: "10 minutes ago",
      body: "This Pokémon likes to lick its palms that are sweetened by being soaked in honey. Teddiursa concocts its own honey by blending fruits and pollen collected by Beedrill. Blastoise has water spouts that protrude from its shell. The water spouts are very accurate.",
      author: {
        name: "Jacob Warnhalter",
        image: "./images/asset21.jpeg",
      },
    },
    {
      postedAt: "10 minutes ago",
      body: "This Pokémon likes to lick its palms that are sweetened by being soaked in honey. Teddiursa concocts its own honey by blending fruits and pollen collected by Beedrill. Blastoise has water spouts that protrude from its shell. The water spouts are very accurate.",
      author: {
        name: "John Doe",
        image: "./images/asset25.jpeg",
      },
    },
  ];

  return (
    <>
      <Container size="lg" py={150} className="blog-details">
        <Container size="sm">
          <Title weight={800} mb="xl">
            {post.title?.rendered}
          </Title>
          <Group position="apart" className={classes.footer} mb={35}>
            <Center>
              <Avatar
                src={post.featured_image_url}
                size={50}
                radius="xl"
                mr="lg"
              />
              <Text size="lg" fw="700" inline>
                Christina Kray <br />
                <small style={{ color: "#aaa" }}>1 Min Ago</small>
              </Text>
            </Center>

            <Group spacing={8} mr={0}>
              <ActionIcon size={45}>
                <Text size="sm" fw="700">
                  SHARE:{" "}
                </Text>
              </ActionIcon>
              <ActionIcon size={45} className={classes.action}>
                <IconBrandFacebook size={20} color={theme.colors.indigo[6]} />
              </ActionIcon>
              <ActionIcon size={45} className={classes.action}>
                <IconBrandTwitter size={20} color={theme.colors.teal[6]} />
              </ActionIcon>
              <ActionIcon size={45} className={classes.action}>
                <IconBrandInstagram size={20} color={theme.colors.cyan[6]} />
              </ActionIcon>
              <ActionIcon size={45} className={classes.action}>
                <IconShare size={20} color={theme.colors.red[6]} />
              </ActionIcon>
            </Group>
          </Group>
        </Container>

        <Image
          mb={30}
          className="post-thumb"
          radius="md"
          src={post.featured_image_url}
          alt="Post Thumbnail"
        />
        <Container size="sm">
          <TypographyStylesProvider fz={17}>
            <div
              dangerouslySetInnerHTML={{ __html: post.content?.rendered }}
            ></div>
          </TypographyStylesProvider>
          <Group
            position="apart"
            className={classes.footer + " post-author"}
            mb={35}
          >
            <Center>
              <Avatar
                src={post.featured_image_url}
                size={50}
                radius="xl"
                mr="lg"
              />
              <Text size="lg" fw="700" inline>
                Christina Kray <br />
                <small style={{ color: "#aaa" }}>Post Writter</small>
              </Text>
            </Center>

            <Group spacing={8} mr={0}>
              <Button
                leftIcon={<IconPlus />}
                size="md"
                color="red"
                variant="outline"
              >
                Follow
              </Button>
            </Group>
            <p>
              Christina started his recruitment career on the agency side. Since
              then, she’s built a career helping customers get the most out of
              HR technology. She’s currently a Customer Success Specialist at
              Space and spends her time speaking to in-house recruiters all over
              the world - helping them solve their recruitment challenges, and
              get the most out of our talent acquisition software.
            </p>
          </Group>

          <Comments comData={comData} />

          <CommentForm />
        </Container>
      </Container>
    </>
  );
};

export default BlogDetail;

// import { ActionIcon, Avatar, Blockquote, Button, Center, Container, createStyles, Group, Image, List, Text, Title, TypographyStylesProvider } from '@mantine/core';
// import { IconBrandFacebook, IconBrandInstagram, IconBrandTwitter, IconPlus, IconShare } from '@tabler/icons';
// import MainFooter from './footerx';
// import MenuHeader from './headerx';
// import CommentForm from './commentForm';
// import Comments from './comments';

// const useStyles = createStyles((theme) => ({
//     card: {
//       position: 'relative',
//       backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
//     },

//     rating: {
//       position: 'absolute',
//       top: theme.spacing.xs,
//       right: theme.spacing.xs + 2,
//       pointerEvents: 'none',
//     },

//     title: {
//       display: 'block',
//       marginTop: theme.spacing.md,
//       marginBottom: theme.spacing.xs / 2,
//     },

//     action: {
//       backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
//       ...theme.fn.hover({
//         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
//       }),
//       borderRadius: '50px'
//     },

//     footer: {
//       marginTop: theme.spacing.md,
//     },
//   }));

// function BlogDetail () {
//     const { classes, theme } = useStyles();

//     const data = [{
//       postedAt: "10 minutes ago",
//       body: "This Pokémon likes to lick its palms that are sweetened by being soaked in honey. Teddiursa concocts its own honey by blending fruits and pollen collected by Beedrill. Blastoise has water spouts that protrude from its shell. The water spouts are very accurate.",
//       author: {
//         name: "Jacob Warnhalter",
//         image: "./images/asset24.jpeg"
//       }
//     },{
//       postedAt: "10 minutes ago",
//       body: "This Pokémon likes to lick its palms that are sweetened by being soaked in honey. Teddiursa concocts its own honey by blending fruits and pollen collected by Beedrill. Blastoise has water spouts that protrude from its shell. The water spouts are very accurate.",
//       author: {
//         name: "Jacob Warnhalter",
//         image: "./images/asset21.jpeg"
//       }
//     },{
//       postedAt: "10 minutes ago",
//       body: "This Pokémon likes to lick its palms that are sweetened by being soaked in honey. Teddiursa concocts its own honey by blending fruits and pollen collected by Beedrill. Blastoise has water spouts that protrude from its shell. The water spouts are very accurate.",
//       author: {
//         name: "Jacob Warnhalter",
//         image: "./images/asset25.jpeg"
//       }
//     }]

//     return <>
//     <Container size='lg' py={150} className='blog-details'>

//       <Container size='sm'>
//         <Title  weight={800} mb="xl">Announcing a free plan for small teams</Title>
//         <Group position="apart" className={classes.footer} mb={35}>
//             <Center>
//                 <Avatar src={'./images/asset21.jpeg'} size={50} radius="xl" mr="lg" />
//                 <Text size="lg" fw='700' inline>Christina Kray <br/><small style={{color: '#aaa'}}>1 Min Ago</small></Text>
//             </Center>

//             <Group spacing={8} mr={0}>
//                 <ActionIcon size={45}>
//                     <Text size="sm" fw='700'>SHARE: </Text>
//                 </ActionIcon>
//                 <ActionIcon size={45} className={classes.action}>
//                      <IconBrandFacebook size={20} color={theme.colors.indigo[6]} />
//                 </ActionIcon>
//                 <ActionIcon size={45} className={classes.action}>
//                     <IconBrandTwitter size={20} color={theme.colors.teal[6]} />
//                 </ActionIcon>
//                 <ActionIcon size={45} className={classes.action}>
//                     <IconBrandInstagram size={20} color={theme.colors.cyan[6]} />
//                 </ActionIcon>
//                 <ActionIcon size={45} className={classes.action}>
//                     <IconShare size={20} color={theme.colors.red[6]} />
//                 </ActionIcon>
//             </Group>
//         </Group>
//         </Container>
//           <Image mb={30} className='post-thumb'
//             radius="md"
//             src="./images/asset22.jpeg"
//             alt="Post Thumbnail"
//           />

//         <TypographyStylesProvider fz={17}>
//           <Container size='sm'>
//             <Text>
//                 At Front, our mission has always been focused on bringing openness and transparency to the design process. We've always believed that by providing a space where designers can share ongoing work not only empowers them to make better products, it also helps them grow. We're proud to be a part of creating a more open culture and to continue building a product that supports this vision.
//             </Text>
//             <h3>Article itself</h3>
//             <Text>
//                 As we've grown, we've seen how Front has helped companies such as Spotify, Microsoft, Airbnb, Facebook, and Intercom bring their designers closer together to create amazing things. We've also learned that when the culture of sharing is brought in earlier, the better teams adapt and communicate with one another.
//             </Text>
//             <List>
//               <List.Item>Clone or download <a href="#">repository from GitHub</a></List.Item>
//               <List.Item>Install dependencies with yarn</List.Item>
//               <List.Item>To start development server run npm start command</List.Item>
//               <List.Item>Run tests to make sure your changes do not break the build</List.Item>
//               <List.Item>Submit a pull request once you are done</List.Item>
//             </List>
//             <Blockquote cite="– Forrest Gump">
//                 Life is like an npm install – you never know what you are going to get.
//             </Blockquote>
//             <Text>
//                 At Front, our mission has always been <strong>focused on bringing openness and transparency to the design process</strong>. We've always believed that by providing a space where designers can share ongoing work not only empowers them to make better products, it also helps them grow. We're proud to be a part of creating a more open culture and to continue building a product that supports this vision.
//             </Text>
//             <img src="./images/asset22.jpeg" alt="Post Image" />
//             <h4>Designers can share ongoing work</h4>
//             <Text>
//                 As we've grown, we've seen how Front has helped companies such as Spotify, Microsoft, Airbnb, Facebook, and Intercom bring their designers closer together to create amazing things. We've also learned that when the culture of sharing is brought in earlier, the better teams adapt and communicate with one another.
//             </Text>
//           </Container>
//         </TypographyStylesProvider>

//         <Container size='sm'>
//           <Group position="apart" className={classes.footer + ' post-author'} mb={35}>
//             <Center>
//                 <Avatar src={'./images/asset21.jpeg'} size={50} radius="xl" mr="lg" />
//                 <Text size="lg" fw='700' inline>Christina Kray <br/><small style={{color: '#aaa'}}>Post Writter</small></Text>
//             </Center>

//             <Group spacing={8} mr={0}>
//             <Button leftIcon={<IconPlus />} size='md' color='red' variant='outline'>Follow</Button>
//             </Group>
//             <p>
//               Christina started his recruitment career on the agency side. Since then, she’s built a career helping customers get the most out of HR technology. She’s currently a Customer Success Specialist at Space and spends her time speaking to in-house recruiters all over the world - helping them solve their recruitment challenges, and get the most out of our talent acquisition software.
//             </p>
//           </Group>

//           <Comments data={data} />

//           <CommentForm />
//         </Container>

//     </Container>
//   </>
// }

// export default BlogDetail;
