import { Badge, Container, Grid, Group, Image, Text, Title } from '@mantine/core';

function Services() {
  return (
    <section className="services">
         <Container size="lg" mt={150}>
            <Grid align='center'>
                <Grid.Col md={12}>
                    <Group position="center">
                        <Badge variant="light" color='indigo' size="lg">All feature You Need</Badge>
                    </Group>
                    <Title order={1} weight={900} align="center" mt="sm" mb={70}>The perfect solution for online businesses</Title>
                </Grid.Col>
                <Grid.Col sm={6} order={2} orderSm={1}>
                    <Group position="left"
                        sx={(theme) => ({
                            maxWidth: '450px',
                            marginBottom: '90px',
                            '@media (max-width: 767px)': {
                                maxWidth: 'none',
                                marginBottom: '40px'
                            },
                        })}
                    >
                        <Title weight={600} size={30} >Increase customer loyalty through automated sales and support solutions</Title>
                        <Text color={'#677788'}>Boost customer retention and satisfaction with advanced sales and support automation. 
                            By streamlining processes and providing personalized, efficient service, you can enhance customer interactions, 
                            build stronger relationships, and drive long-term loyalty. Automation allows you to focus on delivering exceptional 
                            experiences while increasing overall efficiency.</Text>
                        {/* <Button variant="light" compact size="md" color='indigo' radius="xl" mt='sm' rightIcon={<IconArrowRight size={22} />}>Continue exploring</Button> */}
                    </Group>
                </Grid.Col>
                <Grid.Col sm={6} sx={(theme) => ({marginBottom: '90px', '@media (max-width: 767px)': {marginBottom: '5px'}})} order={1} orderSm={2}>
                    <div style={{background: '#FAE9EE', borderRadius: '10px', paddingTop: 80, paddingLeft: 80, overflow: 'hidden'}}>
                        <Image py={50} px={30} style={{backgroundColor: '#fbfbfb', borderTopLeftRadius: '10px', width: 'unset'}}
                            src="./images/SVG/chat@.svg"
                            height={285}
                            fit="contain"
                            />
                    </div>
                </Grid.Col>
                <Grid.Col sm={6} sx={(theme) => ({marginBottom: '90px', '@media (max-width: 767px)': {marginBottom: '5px'}})} order={3} orderSm={3}>
                    <div style={{background: '#EBF1FE', borderRadius: '10px', paddingTop: 80, paddingRight: 80, overflow: 'hidden'}}>
                        <Image py={50} px={30} style={{backgroundColor: '#fbfbfb', borderTopRightRadius: '10px', border: '5px double #fff', margin: '0 0 -5px -5px', width: 'unset'}}
                            src="./images/SVG/intigration@.svg"
                            height={285}
                            fit="contain"
                            />
                    </div>
                </Grid.Col>
                <Grid.Col sm={6} order={4} orderSm={4}>
                    <Group position="left"
                        sx={(theme) => ({
                            maxWidth: '480px',
                            marginBottom: '90px',
                            marginLeft: 'auto',
                            '@media (max-width: 767px)': {
                                maxWidth: 'none',
                                marginBottom: '40px'
                            },
                        })}
                    >
                        <Title weight={600} size={30} >Intigrations</Title>
                        <Text color={'#677788'}>Effortlessly connect with your favorite apps or current systems, 
                            removing the hassle of managing multiple platforms 
                            and keeping everything centralized in a single hub. This includes tools like 
                            like Slack, Shopify, Facebook, Wordpress and many others that enhance productivity.</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col sm={6} order={6} orderSm={5}>
                    <Group position="left"
                        sx={(theme) => ({
                            maxWidth: '450px',
                            marginBottom: '90px',
                            '@media (max-width: 767px)': {
                                maxWidth: 'none',
                                marginBottom: '40px'
                            },
                        })}
                    >
                        <Title weight={600} size={30} >Innovative Approaches to Document Management</Title>
                        <Text color={'#677788'}>The platform offers effective solutions for managing and securing digital assets. 
                            Easily organize and share files for quick collaboration, while PDF stamping and image watermarking 
                            protect your intellectual property. Document signing streamlines approvals, ensuring secure storage 
                            of important agreements. These features enhance workflow efficiency and safeguard your content.</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col sm={6} sx={(theme) => ({marginBottom: '90px', '@media (max-width: 767px)': {marginBottom: '5px'}})} order={5} orderSm={6}>
                    <div style={{background: '#E6F4F2', borderRadius: '10px', paddingTop: 80, paddingLeft: 80, overflow: 'hidden'}}>
                        <Image py={50} px={30} style={{backgroundColor: '#fbfbfb', borderTopLeftRadius: '10px', width: 'unset'}}
                            src="./images/SVG/files@.svg"
                            height={285}
                            fit="contain"
                            />
                    </div>
                </Grid.Col>
                <Grid.Col sm={6} sx={(theme) => ({marginBottom: '90px', '@media (max-width: 767px)': {marginBottom: '5px'}})} order={7} orderSm={7}>
                    <div style={{background: '#EBF1FE', borderRadius: '10px', paddingTop: 80, paddingRight: 80, overflow: 'hidden'}}>
                        <Image p={30} style={{backgroundColor: '#fbfbfb', borderTopRightRadius: '10px', width: 'unset'}}
                            src="./images/SVG/forms@.svg"
                            height={285}
                            fit="contain"
                            />
                    </div>
                </Grid.Col>
                <Grid.Col sm={6} order={8} orderSm={8}>
                    <Group position="left" 
                        sx={(theme) => ({
                            maxWidth: '480px',
                            marginBottom: '90px',
                            marginLeft: 'auto',
                            '@media (max-width: 767px)': {
                                maxWidth: 'none',
                                marginBottom: '40px'
                            },
                        })}>
                        <Title weight={600} size={30} >Ultimate Solution For Building Any Form</Title>
                        <Text color={'#677788'}>The platform presents a wide range of robust features, such as an 
                            intuitive Drag & Drop Form Builder, Integrated Views, a Visual Styler, Repeater Fields, 
                            a diverse collection of Form Templates, Dynamic Fields, and effortless 1-Click 
                            Add-on Installation, among several others.</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col sm={6} mb={90} order={10} orderSm={9}>
                    <Group position="left" 
                        sx={(theme) => ({
                            maxWidth: '450px',
                            marginBottom: '90px',
                            '@media (max-width: 767px)': {
                                maxWidth: 'none',
                                marginBottom: '40px'
                            },
                        })}
                        >
                        <Title weight={600} size={30} >Transformative Tools for Enhanced Team Communication</Title>
                        <Text color={'#677788'}>Unlock the power of transformative tools designed to elevate team 
                            communication. Foster collaboration with intuitive platforms that streamline 
                            interactions, improve coordination, and boost productivity. Experience 
                            seamless and effective communication, making teamwork more efficient 
                            and connected than ever before.</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col sm={6} sx={(theme) => ({marginBottom: '90px', '@media (max-width: 767px)': {marginBottom: '5px'}})} order={9} orderSm={10}>
                    <div style={{background: '#FAE9EE', borderRadius: '10px', paddingTop: 80, paddingLeft: 80, overflow: 'hidden'}}>
                        <Image py={50} px={30} style={{backgroundColor: '#fbfbfb', borderTopLeftRadius: '10px', width: 'unset'}}
                            src="./images/SVG/team@.svg"
                            height={285}
                            fit="contain"
                            />
                    </div>
                </Grid.Col>

                <Grid.Col sm={6} sx={(theme) => ({marginBottom: '90px', '@media (max-width: 767px)': {marginBottom: '5px'}})} order={11} orderSm={11}>
                    <div style={{background: '#EBF1FE', borderRadius: '10px', paddingTop: 80, paddingRight: 80, overflow: 'hidden'}}>
                        <Image p={30} style={{backgroundColor: '#fbfbfb', borderTopRightRadius: '10px', width: 'unset'}}
                            src="./images/SVG/task@.svg"
                            height={285}
                            fit="contain"
                            />
                    </div>
                </Grid.Col>
                <Grid.Col sm={6} order={12} orderSm={12}>
                    <Group position="left" 
                        sx={(theme) => ({
                            maxWidth: '480px',
                            marginBottom: '90px',
                            marginLeft: 'auto',
                            '@media (max-width: 767px)': {
                                maxWidth: 'none',
                                marginBottom: '40px'
                            },
                        })}>
                        <Title weight={600} size={30} >Elevate Productivity with Advanced Task Management</Title>
                        <Text color={'#677788'}>Enhance productivity with advanced task management tools 
                            designed for efficiency. Organize, prioritize, and track tasks 
                            effortlessly to streamline workflows and enhance team collaboration. 
                            Experience seamless task handling that drives performance 
                            and keeps projects on track.</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col sm={6} order={14} orderSm={13}>
                    <Group position="left" 
                    sx={(theme) => ({
                        maxWidth: '450px',
                        marginBottom: '90px',
                        '@media (max-width: 767px)': {
                            maxWidth: 'none',
                            marginBottom: '40px'
                        },
                    })}>
                        <Title weight={600} size={30} >Comprehensive Content Management: FAQs, Articles, and Documentation</Title>
                        <Text color={'#677788'}>Manage your content effortlessly with a comprehensive solution 
                            for FAQs, articles, and documentation. Streamline the creation, organization, and 
                            updating of essential information, ensuring clarity and accessibility. Enhance 
                            your content strategy with tools designed for efficiency and ease of use.</Text>
                    </Group>
                </Grid.Col>
                <Grid.Col sm={6} sx={(theme) => ({marginBottom: '90px', '@media (max-width: 767px)': {marginBottom: '5px'}})} order={13} orderSm={14}>
                     <div style={{background: '#FAE9EE', borderRadius: '10px', paddingTop: 80, paddingLeft: 80, overflow: 'hidden'}}>
                        <Image py={50} px={30} style={{backgroundColor: '#fbfbfb', borderTopLeftRadius: '10px', width: 'unset'}}
                            src="./images/SVG/articales@.svg"
                            height={285}
                            fit="contain"
                            />
                    </div>
                </Grid.Col>
            </Grid>
         </Container>
    </section>
  );
}

export default Services;
