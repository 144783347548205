import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Paper, Text, Title, Button, useMantineTheme, Grid, Image } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  category: {
    fontSize: 16,
    color: 'violet',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.,
    fontSize: 32,
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xl,
  },

  description: {
    fontSize: 19,
    color: '#677788',
    marginBottom: '25px'
  },
  image: {
    boxShadow: '0 2.75rem 5.5rem -3.5rem rgb(189 197 209 / 50%), 0 2rem 4rem -2rem rgb(33 50 91 / 30%), inset 0 -0.1875rem 0.3125rem 0 rgb(151 164 175 / 50%)',
    background: '#f8fafd',
    padding: '10px',
    borderRadius: '13px'
  }
}));

interface CardProps {
  image: string;
  title: string;
  category: string;
  description: string;
}

function Card({ image, title, category, description }: CardProps) {
  const { classes } = useStyles();

  return (
    <Paper
      px={50}
      radius="md"
      sx={{ background: 'transparent' }}
      className={classes.card}
    >
      <div>
        <Text className={classes.category} size="xs">
          {category}
        </Text>
        <Title order={3} className={classes.title}>
          {title}
        </Title>
        <Text className={classes.description}>
          {description}
        </Text>
      </div>
      <Button variant="filled" color="green" size='md'>
        Explore More
      </Button>
    </Paper>
  );
}

const data = [
  {
    image:
      './images/asset7.jpeg',
    title: 'Start conversations, win loyal customers',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt nulla quam aut sed corporis voluptates praesentium inventore.',
    category: 'Conversation',
  },
  {
    image: './images/asset6.jpeg',
    title: 'Hawaii beaches review: better than you think',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt nulla quam aut sed corporis voluptates praesentium inventore.',
    category: 'Chat App',
  },
  {
    image: './images/asset7.jpeg',
    title: 'Mountains at night: 12 best locations to enjoy the view',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt nulla quam aut sed corporis voluptates praesentium inventore.',
    category: 'Exclisive View',
  },
];

export function Carousels(props:any) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <Grid>
        <Grid.Col lg={6}>
          <Image
            radius='md'
            src={item.image} 
            className={classes.image}
          />
        </Grid.Col>
        <Grid.Col lg={6}>
          <Card {...item} />
        </Grid.Col>
      </Grid>
    </Carousel.Slide>
  ));

  return (
    <Carousel
      controlSize={40}
      slideSize="100%"
      // withIndicators
      breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: 2 }]}
      slideGap="xl"
      align="start"
      slidesToScroll={mobile ? 1 : 1}
      styles={{
        control: {
          '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
          },
        },
        indicator: {
          width: 16,
          height: 4,
          transition: 'width 250ms ease',

          '&[data-active]': {
            width: 25,
          },
        },
      }}
    >
      {slides}
    </Carousel>
  );
}
export default Carousels;