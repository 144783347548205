
import {
    Paper,
    Text,
    TextInput,
    Grid,
    Button,
    Image,
    SimpleGrid,
    createStyles,
    Container,
    Title,
    Flex,
    Card,
    Group,
  } from '@mantine/core';
import { IconChevronRight, IconCodeCircle, IconPencilPlus, IconQuestionCircle } from '@tabler/icons';

 
  const useStyles = createStyles((theme) => {
    const BREAKPOINT = theme.fn.smallerThan('sm');
  
    return {
      wrapper: {
        background: '#4c6ef5',
        borderRadius: theme.radius.lg,
        padding: 4,
        border: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]
        }`,
  
        // [BREAKPOINT]: {
        //   flexDirection: 'column',
        // },
      },
      description: {
        maxWidth: 600,
        margin: 'auto'
      },
      card: {
        textAlign: 'center'
      },
  
      form: {
        boxSizing: 'border-box',
        flex: 1,
        padding: theme.spacing.xl,
        paddingLeft: theme.spacing.xl * 2,
        borderLeft: 0,
  
        [BREAKPOINT]: {
          padding: theme.spacing.md,
          paddingLeft: theme.spacing.md,
        },
      },
  
      fields: {
        marginTop: -12,
      },
  
      fieldInput: {
        flex: 1,
  
        '& + &': {
          marginLeft: theme.spacing.md,
  
          [BREAKPOINT]: {
            marginLeft: 0,
            marginTop: theme.spacing.md,
          },
        },
      },
  
      fieldsGroup: {
        display: 'flex',
  
        [BREAKPOINT]: {
          flexDirection: 'column',
        },
      },
  
      contacts: {
  
        [BREAKPOINT]: {
          marginBottom: theme.spacing.sm,
          paddingLeft: theme.spacing.md,
        },
      },
  
      title: {
        marginBottom: theme.spacing.xl * 1.5,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  
        [BREAKPOINT]: {
          marginBottom: theme.spacing.xl,
        },
      },
  
      control: {
        [BREAKPOINT]: {
          flex: 1,
        },
      },
    };
  });
  
  export function Reviews() {
    const { classes } = useStyles();
  
    return (
        <Container size="lg" py={100}>

            <Paper radius="lg">
                <Grid justify="center" align="center" className={classes.wrapper}>
                    <Grid.Col md={4} className={classes.contacts}
                    sx={(theme) => ({
                      '@media (max-width: 991px)': {
                        display: 'none'
                      }})}
                    >
                        <Image
                            radius="lg"
                            src="./images/ceo.png"
                            alt="Random unsplash image"
                        />
                    </Grid.Col>
                    <Grid.Col md={8}
                      sx={(theme) => ({
                        padding: '0 60px',
                      '@media (max-width: 991px)': {
                        padding: '30px'
                      }})}
                    >
                        <div>
                            <Image width={50} 
                                src="./images/quote.svg"
                                alt="Random unsplash image"
                            />
                            <Text color='#fff' fz={16} fw={100} lh='1.7' mt={'xl'} mb={20}>
                                “CommFlow has been an excellent tool for streamlining our communication. We use it for live customer chats, team messaging, and building forms like contact and feedback forms. Its user-friendly interface makes managing everything simple and efficient. Overall, it's been a great asset for both customer interactions and team collaboration.”
                            </Text>
                            <div>
                                <Image 
                                sx={(theme) => ({
                                  display: 'none',
                                '@media (max-width: 992px)': {
                                  display: 'block',
                                  paddingBottom: '10px'
                                }})}
                                  width={40} 
                                    radius="xl"
                                    src="./images/ceo.png"
                                    alt="Random unsplash image"
                                />
                                <Title color='#fff' order={4} mb={5}>Shahnur Alam</Title>
                                <Text fz={11} c='#eee'>CEO, W3 Eden, Inc.</Text>
                              </div>
                        </div>
                    </Grid.Col>
                </Grid>
            </Paper>
        </Container>
    );
  }
  export default Reviews;