import Carousels from './carousels';
import Services from './services';
import CTA from './CTA';
import { Container } from '@mantine/core';

function FeaturedPage() {
  return (
    <>
        <section style={{padding: '180px 0', background: '#C6D1F8'}}>
          <Container size='lg'>
            <Carousels />
          </Container>
        </section>
        <Services />
        <CTA />
    </>
  );
}

export default FeaturedPage;
