import {
    Paper,
    Text,
    TextInput,
    Textarea,
    Button,Image,
    Group,
    SimpleGrid,
    createStyles,
    Container,
    Title,
    Flex,
    Card,
  } from '@mantine/core';
import { IconChevronRight, IconCodeCircle, IconPencilPlus, IconQuestionCircle } from '@tabler/icons';

  const mockdata = [
    {
      title: 'Knowledgebase',
      description:
        'This dust is actually a powerful poison that will even make a pro wrestler.',
      icon: IconPencilPlus,
      button: 'Contact Support'
    },
    {
      title: 'FAQ',
      description:
      'This dust is actually a powerful poison that will even make a pro wrestler.',
      icon: IconQuestionCircle,
      button: 'Visit FAQ'
    },
    {
      title: 'Deveper API',
      description:
      'This dust is actually a powerful poison that will even make a pro wrestler.',
      icon: IconCodeCircle,
      button: 'Contact Sales'
    },
  ];
  
  const useStyles = createStyles((theme) => {
    const BREAKPOINT = theme.fn.smallerThan('sm');
  
    return {
      wrapper: {
        display: 'flex',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        borderRadius: theme.radius.lg,
        padding: 4,
        border: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]
        }`,
  
        [BREAKPOINT]: {
          flexDirection: 'column',
        },
      },
      description: {
        maxWidth: 600,
        margin: 'auto'
      },
      card: {
        textAlign: 'center'
      },
  
      form: {
        boxSizing: 'border-box',
        flex: 1,
        padding: theme.spacing.xl,
        paddingLeft: theme.spacing.xl * 2,
        borderLeft: 0,
  
        [BREAKPOINT]: {
          padding: theme.spacing.md,
          paddingLeft: theme.spacing.md,
        },
      },
  
      fields: {
        marginTop: -12,
      },
  
      fieldInput: {
        flex: 1,
  
        '& + &': {
          marginLeft: theme.spacing.md,
  
          [BREAKPOINT]: {
            marginLeft: 0,
            marginTop: theme.spacing.md,
          },
        },
      },
  
      fieldsGroup: {
        display: 'flex',
  
        [BREAKPOINT]: {
          flexDirection: 'column',
        },
      },
  
      contacts: {
        boxSizing: 'border-box',
        position: 'relative',
        borderRadius: theme.radius.lg - 2,
        backgroundColor: 'rgba(55, 125, 255, 0.5)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '1px solid transparent',
        display: 'flex',
        flex: '0 0 350px',
        
      },
  
      title: {
        marginBottom: theme.spacing.xl * 1.5,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  
        [BREAKPOINT]: {
          marginBottom: theme.spacing.xl,
        },
      },
  
      control: {
        [BREAKPOINT]: {
          flex: 1,
        },
      },
    };
  });
  
  export function Contact() {
    const { classes } = useStyles();
    // const features = mockdata.map((feature) => (
    //   <Card key={feature.title} className={classes.card} p="xl">
    //     <feature.icon size={50} color='violet' />
    //     <Text size="lg" weight={700} mt="md">
    //       {feature.title}
    //     </Text>
    //     <Text size="md" color="dimmed" mt="sm">
    //       {feature.description}
    //     </Text>
    //     <Button mt='lg' rightIcon={<IconChevronRight size={18} />} variant="subtle" color="indigo" radius="xl" compact>
    //       {feature.button}
    //     </Button>
    //   </Card>
    // ));
  
    return (
        <>
        <Flex
        justify="center"
        align="center"
        direction="column"
        wrap="wrap-reverse"
        pt={150} pb={70}>
            <Title className={classes.title} align="center" weight={800} mb="sm">Contact Us</Title>
            <Text>We'd love to talk about how we can help you.</Text>
        </Flex>
        <Container size="lg" pb={100}>

          {/* <SimpleGrid cols={3} spacing="xl" pb={150}>
            {features}
          </SimpleGrid> */}

            <Paper shadow="md" radius="lg">
                <div className={classes.wrapper}>
                    <div className={classes.contacts}>
                        <Image p={30} style={{marginTop: 'auto'}}
                            src="./images/SVG/contact@.svg"
                            fit="contain"
                            />
                    </div>
            
                    <form className={classes.form} onSubmit={(event) => event.preventDefault()}>
                        <Text fz='xl' weight={800} className={classes.title}>
                        Tell us about yourself
                        </Text>
            
                        <div className={classes.fields}>
                        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                            <TextInput size='lg' mb="xl" placeholder="Your name" />
                            <TextInput size='lg' mb="xl" placeholder="hello@mantine.dev" required />
                        </SimpleGrid>
            
                        <TextInput size='lg' mb="xl" placeholder="Subject" required />
            
                        <Textarea size='lg' mb="xl" 
                            mt="md"
                            placeholder="Please include all relevant information"
                            minRows={3}
                        />
            
                        <Group mt="md">
                            <Button size='lg' color='indigo' type="submit" className={classes.control}>
                            Send message
                            </Button>
                        </Group>
                        </div>
                    </form>
                </div>
            </Paper>
        </Container>
        </>
    );
  }
  export default Contact;