import { Title, Button, Image, Container, Grid } from '@mantine/core';

export function CTA() {
  return (
    <Container my={70} size="lg" style={{position: 'relative'}}>
      <div className='cta pattern'>
            <Grid gutter={30}>
              <Grid.Col sm={6}>
                <Title fz={26} mt={50}>
                  Join with us Today for Instant Communication, Real-Time Support, and Seamless Conversations!
                </Title>
                <a href='https://app.commflow.io' target='blank'><Button size='md' radius="sm" my={30} color='orange' variant="gradient" gradient={{ from: 'pink', to: 'orange', deg: 237 }}>Try CommFlow Now</Button></a>
              </Grid.Col>
              <Grid.Col sm={6}>
                <div className='img-wrapper'>
                  <Image 
                      sx={(theme) => ({
                      '@media (max-width: 767px)': {
                        display: 'none',
                      }})}
                    radius="md"
                    src="./images/cta.jpg"
                    alt="Random unsplash image"
                  />
                </div>
              </Grid.Col>
            </Grid> 
        </div>
        <img style={{position: 'absolute', left: '-40px', top: '-55px', maxWidth: '150px'}} src="./images/asset17.svg" alt="Shape" />
      </Container>
  );
}
export default CTA;