import { Container, Grid, Image, Title, Text } from '@mantine/core';
;

function HomePage() {
  return (
    <section className="services">
        <Container size="lg" pt={100} pb={150}>
            <Title align="center" weight={900} order={1} pb={70}><Title order={2} weight={100} color='green' pb={5}>Commflow Loved by</Title> So Many popular Companies!</Title>
            <Image style={{margin: '0 auto'}}
                width={'100%'}
                src="./images/clients-gray.png"
            />
            <br/>
            <Title pt={10} align='center' order={5} weight={100} fz={'sm'}>- With over 50+ active clients and more in progress.</Title>
         </Container>
    </section>
  );
}

export default HomePage;
